<template>
    <!-- eslint-disable -->
    <v-container fluid class="bs-container pa-0">
        <OnboardingNavBar/>
        <div class="main">
            <div class="nav-header">
                <div class="nav-header-icon backIcon">
                    <div v-on:click="back" class="nav-icon">
                        <img src="@/assets/svg/nav-back.svg" class="nav-icon-styles" alt="arrow left" />
                    </div>
                </div>
                <div class="nav-header-content">
                    <div class="text">
                        <div class="legend">Speak any other <p class="legend2"> languages?</p></div>
                        <div class="subtext">
                            Add any other languages you can speak.
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-main">
                <div class="lang-preferences-container">
                    <div class="lang-preference-header">
                        <div class="lp-header-col">LANGUAGE</div>
                        <div class="lp-header-col">PROFICIENCY</div>
                    </div>
                    <div class="lang-preference-list">
                        <div
                            class="lp-item"
                            v-for="(languagePreference, index) in languagePreferences"
                        >
                            <div class="lp-item-col"
                            >
                                <div
                                   v-if="languagePreference.language !== 'english'"
                                >
                                    <v-combobox
                                        v-model="languagePreference.language"
                                        :items="$hyper.languages"
                                        item-text="name"
                                        :return-object="false"
                                        :item-value="(obj) => obj.name"
                                        outlined
                                        hide-details
                                        class="select-lang"
                                    />
                                </div>
                                <div class="english" v-else>
                                    {{languagePreference.language && languagePreference.language}}
                                </div>
                            </div>
                            <div class="lp-item-col select">
                                <v-select
                                    :items="options"
                                    v-model="languagePreference.proficiency"
                                    item-text="name"
                                    item-value="id"
                                    append-icon="mdi-chevron-down"
                                    hint="hint"
                                    outlined
                                    hide-details
                                    class="select-lang"
                                >
                                 <template v-slot:selection="{ item }">
      {{ item.name }}
    </template>
    <template v-slot:item="{ item }">
    <div class="subtext">
      <div style="color: #262626 !important; font-size:16px !important;">{{ item.name }}</div>
       <div style="color: #929292 !important; font-size:13px !important;">{{ item.hint }}</div>
    </div>
    </template>
    </v-select>
                                
                            </div>
                           
                        </div>
                    </div>
                    <div class="add-lang-btn-wrapper">
                        <div class="add-lang-btn" @click="() => addLanguage()">
                            +Add Language
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider2"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                <div class="continue-btn-wrapper d-flex flex-column">
                    <div class="bs-divider"></div>
                    <v-btn
                        class="continue-btn"
                        depressed
                        @click="submit"
                    >
                        Continue
                    </v-btn>
                </div>
            </div>
        </div>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'LanguagePreference',
  components: {
    OnboardingNavBar,
  },
  data() {
    return ({
      languagePreferences: [],
      options: [
        {
          id: '1',
          name: 'Basic',
          hint: 'I can form basic sentences.',
        },
        {
          id: '2',
          name: 'Conversational',
          hint: 'I am able to have casual conversation.',
        },
        {
          id: '3',
          name: 'Fluent',
          hint: 'I write and speak this language at a high level.',
        },
        {
          id: '4',
          name: 'Native or Bilingual',
          hint: 'I write and speak this language perfectly.',
        },
      ],
    });
  },
  methods: {
    ...mapActions('onboarding', ['saveCreativeLanguages']),
    ...mapActions('profile', ['getSavedProfile']),
    addLanguage() {
      this.languagePreferences.push({
        language: '',
        proficiency: '',
      });
    },
    removeLanguage(index) {
      const data = this.languagePreferences;
      data.splice(index, 1);
      this.languagePreferences = data;
    },
    async submit() {
      const formData = {};
      formData.languages = JSON.stringify(this.languagePreferences);
      this.loading = true;
      const response = await this.saveCreativeLanguages(formData);
      this.loading = false;
      if (response) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'creative',
        });
        if (nextStep.url) {
          this.$router.push({
            path: nextStep.url,
          });
        }
      }
    },
    back() {
      this.$router.push({
        path: '/onboarding/creative/about-you',
      });
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  async mounted() {
    await this.getSavedProfile();
    // add english as default language, should happen conditionally.
    // only if languagePreferences is empty.
    // should load initial info from server.
    if (Number(this.userDetails.onboardingState) < 5) {
      this.$router.push({
        path: '/',
      });
    }
    if (this.userDetails.preferredLanguages.length) {
      this.languagePreferences = this.userDetails.preferredLanguages;
    } else {
      this.languagePreferences.push({
        language: 'english',
        proficiency: '4',
      });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections{
  overflow-wrap:anywhere !important;
  flex-wrap:unset !important;
}
.backIcon {
  cursor: pointer;
}
.legend2{
  margin-top:-69px;
  margin-left:358px;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.main {
    padding: 2rem 4rem;
    margin: auto;
}
.content-main {
    min-height: 300px;
    padding-left: 5rem;
}
.nav-header {
    display: flex;
    // align-items: center;
}
.nav-header-icon {
    margin-right: 4rem;
    margin-top: 1rem;
}
.nav-header-content .text{
  margin-left: 0.8rem;
}
.nav-header .text .legend {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    // font-size: 2rem;
    font-size: 46px;
}
.nav-header .text .subtext {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
}
.nav-icon-styles{
  margin-top:8px;
  margin-left:-10px;
}
.lang-preference-header {
    display: flex;
    padding: 1rem;
    background: #CFF7EE;
}
.lang-preference-header .lp-header-col {
    flex: 1;
}
.lang-preferences-container {
    margin-top: 2rem;
}
.lp-item {
    display: flex;
    padding: 1rem;
    align-items: center;
    border-bottom: 1px solid #DEDEDE;
    .lp-item-col {
        flex: 1;
    }
}
.select-lang {
    max-width: 250px;
}
.add-lang-btn-wrapper {
    margin-top: 2rem;
    margin-left: 1rem;
}
.add-lang-btn {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    color: #8066FF;
    cursor: pointer;
    display: inline-block;
}
.divider2{
  margin-bottom: 1rem;
  width:100%;
  border-top: 1px solid #DEDEDE;
}
.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.english {
  text-transform:capitalize;
}
@media screen and (max-width: 600px) {
  .legend2{
    margin-left: 0px;
    margin-top: 10px;
  }
  .add-lang-btn-wrapper {
    display: flex;
    justify-content: center;
  }
  .rowDisplay {
    display: block;
  }
  .content-main {
   padding-left: 0px;
   margin-left:0.8rem;
  }
  .main {
    padding: 2rem;
  }
  .nav-icon-styles{
    width:10px;
    height: 20px;
  }
  .nav-header-content .text{
    margin-left: 0rem;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
    margin-top:0.8rem;
  }
  .profile-info-section {
    padding: 30px;
  }
  .subtext {
    display: none;
  }
  .continue-btn-wrapper{
    max-width: 100%;
    margin-left:0rem;
  }
  .continue-btn{
    width:335px;
    margin-left:0rem !important;
    margin-right:0rem !important;
  }
  .content-main-actions{
    display: flex;
    justify-content: center !important;
  }
  .lp-item .lp-item-col{
    flex: 1;
    padding: 10px;
    min-width: 100px;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 0.5rem;
  }
  .bs-divider {
    margin: 2rem 0rem;
    border-top: 1px solid #DEDEDE;
  }
  .divider2{
    display: none;
  }
  .subtext {
    display: block !important;

  ::v-deep .row {
    .main-text {
font-family: 'Poppins' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 16px !important;
line-height: 24px !important;
color: #262626 !important;
  }
  .sub-text {
    font-family: 'Poppins' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 13px !important;
line-height: 18px !important;

color: #929292 !important;
  }
  }
  }
}
</style>
